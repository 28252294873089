import {authStore} from "~/stores/auth";

export default defineNuxtRouteMiddleware(async () => {
    const app = useNuxtApp()
    const store = await authStore(app.$pinia)

    try {
        if (store.user === null) {
            return navigateTo('/login')
        }

        if (store.user === undefined) {
            await store.initAuth()

            const user = store.user

            if (!user) {
                return navigateTo('/login')
            }
        }
    } catch (e) {
        console.error(e)
        return navigateTo('/login')
    }
})